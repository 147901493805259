/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $(document).ready(function() {
        // JavaScript to be fired on all pages
          function sliderHeight() {
            var newHeight = $(window).width() * 0.2765625 + 'px';
            $('.coin-slider, .cs-metaslider_151').css('height', newHeight);
          }
          function eventImageHeight() {
            var eventImage = $('#events-popup .event .event-datetime');
            var newHeight = $(eventImage).width();
            $(eventImage).css('height', newHeight);
          }
          window.sliderHeight = sliderHeight;
          window.eventImageHeight = eventImageHeight;
          sliderHeight();
          setTimeout(sliderHeight, 400);
          var resizeTimer;
          $(window).on('resize', function(e) {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(function() {
              eventImageHeight();
              sliderHeight();
            }, 250);
          });
          if (typeof Modernizr !== 'undefined') {
            if (!Modernizr.flexbox && !Modernizr.flexwrap) {
              var container = document.body;
              flexibility(container);
              var onresizeTimeout;
              window.onresize = onresize;
              function onresize() {
                window.onresize = null;
                if (!onresizeTimeout) {
                  onresizeTimeout = setTimeout(function () {
                    onresizeTimeout = null;
                    flexibility(container);
                    window.onresize = onresize;
                  }, 1000 / 60);
                }
              }
            }
          }
          function PopupCenter(url, title, w, h) {
            // Fixes dual-screen position                         Most browsers      Firefox
            var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
            var dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;
            var screenWidth = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
            var screenHeight = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
            var popupWidth = screenWidth * (w / 100);
            var popupHeight = screenHeight * (h / 100);
            var left = ((screenWidth / 2) - (popupWidth / 2)) + dualScreenLeft;
            var top = ((screenHeight / 2) - (popupHeight / 2)) + dualScreenTop;
            var newWindow = window.open(url, title, 'scrollbars=yes, width=' + popupWidth + ', height=' + popupHeight + ', top=' + top + ', left=' + left);
            if (window.focus) {
              newWindow.focus();
            }
          }
          $('.donate').on('click', function (e) {
            e.preventDefault();
            var $this = $(this);
            // URL, Title, Width in percents, Height in percents
            PopupCenter($this.attr('href'), 'Donate','75','75');
          });
          $('.opens-popup').leanModal({ top : 100, overlay : 1, closeButton: '.popup-close' });
          $('.mobile-menu-trigger').find('.trigger').on('click', function(e){
            e.preventDefault();
            $(this).toggleClass('is-triggered');
            var mobileMenu = $('.mobile-menu');
            $('.mobile-menu-icon').toggleClass('is-clicked');
            if( mobileMenu.hasClass('is-visible') ) {
              mobileMenu.removeClass('is-visible').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',function(){
                $('body').removeClass('overflow-hidden');
              });
            } else {
              mobileMenu.addClass('is-visible').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',function(){
                $('body').addClass('overflow-hidden');
              });
            }
          });
          $('.share-buttons').children('a').on('click', function (e) {
            e.preventDefault();
            var $this = $(this);
            PopupCenter($this.attr('href'), 'Donate','75','45');
          });
          $('.owl-carousel').owlCarousel({
            center: true,
            items: 1,
            loop: true,
            nav: true,
            navText: ['<i class="fa fa-angle-left" aria-hidden="true"></i>', '<i class="fa fa-angle-right" aria-hidden="true"></i>']
          });
          $('.wpcf7').on('submit', function(e) {
            setTimeout(function() {
              $('.wpcf7-mail-sent-ok').hide();
            }, 15000);
          });
        });
      },
      finalize: function() {
        $(window).load(function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
          sliderHeight();
          $('.single-events').find('.gallery-loader').fadeOut('slow');
          $('.single-events').find('.gallery').fadeTo('slow', 1).masonry({
            itemSelector: '.gallery-item',
            columnWidth: '.gallery-item',
            percentPosition: true,
            transitionDuration: '0.5s',
            gutter: '.gallery-gutter'
          });
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
